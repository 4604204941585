<template>
  <div class="Site PageBox">
    <van-nav-bar
        fixed
        :border="false"
        :title="$t('hall.default[2]')"
        left-arrow
        @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <div v-if="pageState==0">
        <div class="header0">
          <div class="MyHeader2">
            <img class="head" :src="`./static/head/${UserInfo.header}`" />
          </div>
          <div>{{ UserInfo.username.substring(0,15) }}</div>
        </div>
        <div class="Panel" style="line-height: 25px;color:var(--font_subtitle)">
          <div>
            <div style="font-size:15px;color: var(--font_color)">{{ UserInfo.username.substring(0,15) }}</div>
            <div>{{$t('bindAccount.fields[0]')}} :{{CardInfo.name}}</div>
          </div>
          <div>
            <div style="font-size:15px"> USDT{{ $t("hall.default[1]") }}:
              <span style="color:  var(--font_color2);font-weight: bold;font-size: 16px">{{ parseFloat(UserInfo.balance).toFixed(2)}}</span></div>
            <div>{{$t('buy.label[2]')}} :{{CardInfo.card_no}}</div>
          </div>
        </div>
        <div class="Panel" style="display: flex;padding: 15px">
          <div style="line-height: 25px;width: 50%">
            <div style="color:  var(--font_color2);font-size: 25px;font-weight: bold">{{parseFloat(CoinInfo.price).toFixed(2)}}</div>
            <div>{{$t('sell.label[3]')}}</div>
            <div style="color: var(--font_subtitle)">USDT</div>
          </div>
          <div style="line-height: 25px;width: 50%">
            <div style="font-size: 25px;font-weight: bold;color:var(--font_color)">{{parseFloat(CoinInfo.bestPrice).toFixed(2)}}</div>
            <div>{{$t('sell.label[4]')}}</div>
            <div style="color: var(--font_subtitle)">USDT</div>
          </div>
        </div>
        <div>
          <div class="infoBox">
            <div style="display: flex">
              <van-field required
                         class="input" :border="false"
                         v-model="BankInfo.price"
                         :label="$t('buy.label[5]')"
                         :placeholder="$t('buy.placeholder[0]')"
              />
              <van-button  size="min" style="width: 140px;height: 35px" @click="setPrice">
                <span style="font-size: 14px">{{$t('buy.label[7]')}}</span>
              </van-button>
            </div>
            <div class="line11"></div>
            <div style="display: flex">
              <van-field required
                         class="input" :border="false"
                         v-model="BankInfo.total_number"
                         :label="$t('buy.label[6]')"
                         :placeholder="$t('buy.placeholder[1]')"
              />
            </div>
            <div class="line11"></div>
            <div style="display: flex">
              <van-field required readonly
                         class="input" :border="false"
                         v-model="counttimestr" is-link
                         @click="showPicker = true"
                         :label="$t('buy3[0]')"
                         :placeholder="$t('buy.placeholder[1]')"
              />
            </div>
            <div class="line11"></div>
            <van-popup v-model:show="showPicker" round position="bottom">
              <van-picker show-toolbar :confirm-button-text="$t('common[2]')"
                          :cancel-button-text="$t('common[3]')"
                          :columns="columns" is-link
                          @cancel="showPicker = false"
                          @confirm="onConfirm"
              >
                <template #option="option">
                  <div>
                    <div :style="BankInfo.counttime+' '+$t('buy3[1]')==option?'color:var(--button_bg0)':''" >{{ option }}</div>
                  </div>
                </template>
              </van-picker>
            </van-popup>
            <div style="display: flex">
              <van-field required readonly
                         class="input" :border="false"
                         v-model="ordertypestr" is-link
                         @click="showPicker2 = true"
                         :label="$t('buy3[2]')"
                         :placeholder="$t('buy.placeholder[1]')"
              />
            </div>
            <div class="line11"></div>
            <van-popup v-model:show="showPicker2" round position="bottom">
              <van-picker show-toolbar :confirm-button-text="$t('common[2]')"
                          :cancel-button-text="$t('common[3]')"
                          :columns="columntypes"
                          @cancel="showPicker2 = false"
                          @confirm="onConfirmType"
              >
                <template #option="option">
                  <div>
                    <div :style="ordertypestr==option?'color:var(--button_bg0)':''" >{{ option }}</div>
                  </div>
                </template>
              </van-picker>
            </van-popup>
            <div style="display: flex" v-if="BankInfo.ordertype==0">
              <van-field
                  class="input" :border="false"
                  v-model="BankInfo.target_uid"
                  :label="$t('buy3[7]')"
                  :placeholder="$t('buy3[8]')"
              />
            </div>
            <div style="display: flex" v-if="BankInfo.ordertype==1">
              <van-field
                  class="input" :border="false"
                  v-model="BankInfo.count_limit"
                  :label="$t('buy3[5]')"
                  :placeholder="$t('buy3[6]')"
              />
            </div>
            <div class="line11"></div>
            <div style="display: flex">
              <van-field required :border="false" class="input"
                         v-model="BankInfo.min_number"
                         :label="$t('buy.label[10]')"
                         :placeholder="$t('buy.placeholder[2]')"
              />
              <van-field required border class="input"
                         v-model="BankInfo.max_number"
                         :placeholder="$t('buy.placeholder[3]')"
              />
            </div>
          </div>
        </div>
        <div class="centerPanel">
          <van-button @click="onSubmit">{{ $t('buy.label[9]') }}</van-button>
        </div>
      </div>
      <div class="completePanel" v-else>
        <div>
          <img src="../../public/static/icon/btn_done.png" style="width:50px"/>
        </div>
        <div style="font-size: 22px">{{$t('buy2[1]')}}</div>
        <div>{{BankInfo.total_number}}{{$t('buy2[2]')}}</div>
        <div style="margin: 10px auto;">
          <van-button block to="/hall">
            <span style="font-size: 18px">{{ $t('sell4[5]') }}</span>
          </van-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BindAccount',
  components: {

  },
  props: [],
  data() {
    return {
      tabsActive: 0,
      weixinAcc: '',
      pageState:0,
      showUsdtType: false,
      showPicker:false,
      showPicker2:false,
      CardInfo:{},
      isVertical:true,
      robotList:[],
      counttimes:[2,6,12,24,72,500,2000],
      counttimestr:'',
      ordertypestr:'',
      columns : [],
      columntypes:[],
      BankInfo:{price:null,total_number:null,min_number:null,max_number:null,counttime:2,ordertype:0,count_limit:null,target_uid:null},
      CoinInfo:{price:0,bestPrice:0},
      douyinImg: [],
      kuaishouImg: [],
      accountArr: [],
      tabsList: [],
    }
  },
  computed: {
  },
  watch: {

  },
  created() {
    this.$Model.GetUserInfo()
    this.$Model.GetBankCardList(data=>{
      this.CardInfo = data.data[0];
    });
    this.counttimes.forEach(tt=>{
      var ctime = tt+" "+this.$t("buy3[1]");
      this.columns.push(ctime);
    });
    this.counttimestr = this.columns[0];
    this.BankInfo.counttime = this.counttimes[0];

    this.ordertypestr = this.$t("buy3[3]");
    this.columntypes.push(this.$t("buy3[3]"))
    this.columntypes.push(this.$t("buy3[4]"))

    this.$Model.GetCommonData(data=>{
      let currs = data.currencylist;
      currs.forEach(item=>{
        if (item['name']=="USDT"){
          this.CoinInfo = item;
          this.CoinInfo['bestPrice'] = item['price'];
        }
      })
    });
    this.$parent.navBarTitle = this.$t('bindAccount.default[0]')
    // this.tabsList = this.InitData.taskclasslist.filter(item=>item.bind_status==1&&item.state==1)
    // this.accountArr = this.tabsList.flatMap(item=>item.bind_type==2?[this.UserInfo[item.bind_field]?[{url:this.ApiUrl+this.UserInfo[item.bind_field]}]:[]]:(this.UserInfo[item.bind_field]||''))
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    setPrice(){
      this.BankInfo.price=this.CoinInfo.bestPrice
    },
    setAmount(){
      this.BankInfo.total_number=this.UserInfo.balance;
    },
    onSubmit() {
      if (this.BankInfo.price<=0){
        this.$Dialog.Toast(this.$t('mytask2[9]'));
        return;
      } else if (this.BankInfo.total_number<=0){
        this.$Dialog.Toast(this.$t('mytask2[7]'));
        return;
      } else if (this.BankInfo.min_number<=0||this.BankInfo.max_number<=0||this.BankInfo.min_number>this.BankInfo.max_number){
        this.$Dialog.Toast(this.$t('mytask2[10]'));
        return;
      }
      this.BankInfo.name = this.CardInfo['name'];
      this.BankInfo.card_no = this.CardInfo['card_no'];
      this.BankInfo.task_type = 1;
      this.$Model.SubmitTask(
          this.BankInfo,
          (data) => {
            let msg = '';
            switch (data.code){
              case 1:
                msg = this.$t('common3[0]');
                break;
              case -1:
                msg = this.$t('mytask2[7]');
                break;
              case -2:
                msg = this.$t('mytask2[8]');
                break;
              case -3:
                msg = this.$t('mytask2[9]');
                break;
            }
            this.$Dialog.Toast(msg);
            if (data.code==1){
              this.$router.push("/hall");
            }
          }
      );
    },
    onConfirm(obj,index){
      console.log(index);
      this.counttimestr = this.columns[index];
      this.showPicker = false;
      this.BankInfo.counttime = this.counttimes[index]
    },
    onConfirmType(obj,index){
      console.log(index);
      this.ordertypestr = obj;
      this.showPicker2 = false;
      this.BankInfo.ordertype = index;
    },
    // onChangeType(obj,value){
    //   this.UserInfo.usdttype = value;
    //   this.showUsdtType = false;
    // },
  }
}
</script>
<style scoped>
.PageBox {
  overflow-y: auto;

}
.ScrollBox {
  padding-top: 10px;
}
.van-nav-bar {

}

.van-cell.input >>> .van-field__control{
  height: 30px;
  line-height: 30px;
    background-color:var(--cell_bg);
    border-radius: 20px;
    padding: 10px;
}

.van-cell>>>.van-cell__title{
  font-size: 15px;
}
.van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image,.van-uploader>>>.van-uploader__preview{
  margin: 0;
  background-color: #f7f8fa;
  border-radius: 8px;
}
.header0 {
  width: 95%;
  border-radius: 10px;
    background:transparent;
  display:flex;
  font-size: 17px;
  padding: 15px;
  padding-left: 15px;
  line-height: 40px;
  margin: 0px auto;
}

.MyHeader2 {
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 42px;
  height: 42px;
  margin-right: 10px;
}
.MyHeader2 img {
  width: 100%;
}
.panelHeader {
  margin-top: 0px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  color: #cccccc;
  width: 40%;
  text-align: center;
  margin-bottom: 10px;
}
.van-cell {
  background: transparent;
}

.PageBox .van-cell>>>.van-cell__title{
  font-size: 15px;
  font-weight: bold;
}
.van-cell >>> .van-field__control {
  padding-left: 7px;
  height: 100%;
}
.van-cell--borderless.input >>> .van-field__control {
    color: var(--cell_title) !important;
    padding-left: 15px;
    font-size: 15px;
}

.van-cell >>> .van-field__control::-webkit-input-placeholder {
  font-family: Alibaba;
    color: var(--cell_placeholder);
}

.van-cell >>> .van-field__control::-moz-placeholder {
  font-family: Alibaba;
    color: var(--cell_placeholder);
}

.van-popup {
  background-color: #4e51bf;
}

.hallswipe {
  width: 96%;
  margin-left: 6px;
  padding: 15px;
  height: 60px;
  z-index: 50;
  margin-top: 5px;
  margin-bottom: 5px;
  color:#cccccc;

}

.robotheader{
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 32px;
  height: 32px;
}
.infoBox {
  margin-left: 10px;
  border-radius: 10px;
  background-color: var(--panel_color);
  padding: 10px;
  margin: 10px;
}

.btn {
  width: 90%;
  padding: 10px 50px;
  border-radius: 5px;
  background-color: var(--button_bg);
  color: #FFFEFF;
  font-size: 18px;
  text-align: center;
  margin: 15px 20px 30px;
}
</style>
